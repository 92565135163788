<span
  *ngIf="layoutService.nameComponents"
  class="alert alert-info"
>document-tab-buttons.component.html</span>
<div class="row mb-5">

  <div class="col p-0">
    <div
      class="pt-4 col-12"
      [appLayout]="layoutService.baseColorSurface"
    >
      <div class="row p-0 mb-3">
        <div class="col mx-4 ">
          <table>
            <tr>
              <td class="pr-5">
                <div class="d-flex">
                  <i
                    class="ri-time-line pr-2"
                    style="line-height: 24px"
                    [appLayout]="layoutService.textColor50"
                  ></i>
                  <span style="line-height: 24px">{{selectedEvent.start| timezone: this.pack?.startDate :
                    selectedEvent.day : this.userTimezone :
                    this.packTimezone: false}} -
                    {{selectedEvent.end| timezone: this.startDate : selectedEvent.day : this.userTimezone :
                    this.packTimezone : true}}</span>
                </div>
              </td>
              <td class="pr-5">
                <div class="d-flex">
                  <ng-container
                    *ngIf="
                  !selectedEvent.roomLink  || selectedEvent.roomLink.trim() == '' || selectedEvent.roomLink.trim() =='-'"
                  >
                    <i
                      class="ri-home-5-line pr-2"
                      style="line-height: 24px"
                      *ngIf="selectedEvent.roomName.trim() != ''"
                      [appLayout]="layoutService.textColor50"
                    ></i>
                    <span>
                      {{selectedEvent.roomName}}</span>
                  </ng-container>

                  <ng-container
                    *ngIf="selectedEvent.roomLink &&  selectedEvent.roomName && selectedEvent.roomLink.trim() !='' && selectedEvent.roomName.trim() !='' && selectedEvent.roomLink.trim() !='-'"
                  >
                    <i class="ri-external-link-line"></i>
                    <i
                      class="ri-link-5-line pr-2"
                      style="line-height: 24px"
                      *ngIf="selectedEvent.roomName.trim() != ''"
                      [appLayout]="layoutService.textColor50"
                    ></i>
                    <a
                      href="{{selectedEvent.roomLink}}"
                      target="_blank"
                      [style.color]="layoutService.activeColor.backgroundColor"
                    ><u>{{selectedEvent.roomName}}</u></a>
                  </ng-container>

                </div>
              </td>
              <td *ngIf="!isParticipantView()">

                <div
                  class=" "
                  *ngFor="let p of allparticipants"
                >
                  <div class="d-flex">
                    <div
                      style="height: 48px ; width:48px; border-radius:50%; overflow: hidden"
                      [appLayout]="layoutService.baseColorButtons"
                      class="mr-2"
                    >
                      <i
                        class="ri-user-2-fill pr-2"
                        style="line-height:  40px;font-size: 45px !important;position: relative; top:10px"
                      ></i>
                    </div>

                    <span style="line-height:  48px">{{p.fullNameWithIndex}}</span>
                  </div>
                </div>
              </td>
              <td style="height:50px">
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col text-white mx-4 my-2">
          <h4>{{selectedEvent.timeblock.name}} <br></h4>
        </div>
      </div>
      <div
        *ngIf="router.url.includes('observer')"
        class="row "
      >
        <div class="col-10 text-left  ">
          <ul class="nav nav-tabs  border-bottom-0 innerTabs ml-4">
            <li
              class="nav-item activehack"
              style="cursor: pointer;"
              routerLinkActive="active"
              [appLayout]="layoutService.baseColorButtons"
              [routerLinkActiveOptions]="{ exact:true }"
              [routerLink]="['/observer',
            'documentview',
            aRoute.parent.snapshot.params.day,
             aRoute.snapshot.params.selectedevent, 'docs']"
            >
              <a class="nav-link ">
                <span i18n>Dokumente</span>
              </a>
            </li>

            <ng-container *ngIf="selectedEvent.timeblock.type === 'exercise'">

              <li
                class="nav-item activehack"
                *ngFor="let p of allparticipants"
                routerLinkActive="active"
                [appLayout]="layoutService.baseColorButtons"
                [routerLinkActiveOptions]="{ exact: false }"
                [routerLink]="['/observer',
            'documentview',
            aRoute.parent.snapshot.params.day,
             aRoute.snapshot.params.selectedevent, 'rating',p.id,selectedEvent.tbid]"
              >
                <a class="nav-link ">
                  <span i18n>Bewertung</span> {{p.fullNameWithIndex}}
                </a>
              </li>
            </ng-container>

            <li
              class="nav-item activehack"
              routerLinkActive="active"
              *ngFor="let survey of selectedEvent.timeblock.surveys"
              [appLayout]="layoutService.baseColorButtons"
              [routerLinkActiveOptions]="{ exact:false }"
              [routerLink]="['/observer','documentview',aRoute.parent.snapshot.params.day,aRoute.snapshot.params.selectedevent, 'survey', survey.surveyid]"
            >
              <a class="nav-link ">
                <span>{{survey.title}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>


      <div
        *ngIf="router.url.includes('participant')"
        class="row "
      >
        <div class="col-12 text-left">
          <ul class="nav nav-tabs  border-bottom-0 innerTabs ml-4">
            <li
              class="nav-item activehack"
              style="cursor:pointer"
              routerLinkActive="active"
              [appLayout]="layoutService.baseColorButtons"
              [routerLinkActiveOptions]="{ exact:true }"
              [routerLink]="['/participant','documentview',aRoute.parent.snapshot.params.day,
             aRoute.snapshot.params.selectedevent, 'docs']"
            >
              <a class="nav-link ">
                <span i18n>Dokumente</span>
                <span
                  *ngIf="selectedEvent.timeblock.prepTime"
                  style="position: relative; top:5px; line-height: 5px !important; cursor: pointer"
                >
                  <i
                    *ngIf="!docsAreLocked()"
                    [ngStyle]="{color:layoutService.activeColor.backgroundColor}"
                    class="ri-timer-2-fill"
                    style="line-height: 5px;"
                  ></i>
                  <i
                    *ngIf="docsAreLocked()"
                    [ngStyle]="{color:layoutService.activeColor.backgroundColor}"
                    class="ri-file-lock-line"
                    style="line-height: 5px; cursor: pointer"
                  ></i>
                </span>
              </a>
            </li>

            <li
              class="nav-item activehack"
              style="cursor: pointer"
              routerLinkActive="active"
              *ngIf="pack?.disableVideofeedback !== 'deactivated' && selectedEvent.timeblock.type === 'exercise'"
              [appLayout]="layoutService.baseColorButtons"
              [routerLinkActiveOptions]="{ exact:false }"
              [routerLink]="['/participant','documentview',aRoute.parent.snapshot.params.day,aRoute.snapshot.params.selectedevent, 'selfrecording']"
            >
              <a class="nav-link ">
                <span i18n>Aufzeichnung der Übung</span>
              </a>
            </li>
            <li
              class="nav-item activehack"
              routerLinkActive="active"
              *ngFor="let survey of selectedEvent.timeblock.surveys"
              [appLayout]="layoutService.baseColorButtons"
              [routerLinkActiveOptions]="{ exact:false }"
              [routerLink]="['/participant','documentview',aRoute.parent.snapshot.params.day,aRoute.snapshot.params.selectedevent, 'survey', survey.surveyid]"
            >
              <a class="nav-link ">
                <span>{{survey.title}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="row px-4"
  *ngIf="selectedEvent.timeblock.prepTime && docsAreLocked()"
>
  <div class="col">
    <h5 i18n>Ihre Vorbereitungszeit ist abgelaufen. Sie können hier keine Dokumente mehr ansehen.</h5>
    <br>

  </div>
</div>