<ng-template
  #authRequestModal
  let-modal
>
  <div
    class="modal-body"
    i18n="@@grantRecordingPermission"
  >
    Aufnahme erlauben?
  </div>
  <div class="modal-footer">
    <div
      class="btn btn-secondary"
      (click)="submitModal(false)"
      i18n="@@globalNo"
    >Nein</div>
    <div
      class="btn btn-primary"
      (click)="submitModal(true)"
      i18n="@@globalYes"
    >Ja</div>
  </div>
</ng-template>

<ng-template
  #addCommentModal
  let-modal
>

  <div
    class="modal-body"
    i18n="@@addCommentToFeedbackInvitation"
  >
    Fügen Sie Ihrer Feedbackeinladung einen Kommentar hinzu.
    <textarea
      #comment
      class="form-control"
    ></textarea>
  </div>
  <div class="modal-footer">
    <div
      class="btn btn-secondary"
      (click)="closeCommentModal()"
      i18n="@@globalNo"
    >Nein</div>
    <div
      class="btn btn-primary"
      (click)="collectComment(comment.value)"
      i18n="@@globalYes"
    >Ja</div>
  </div>
</ng-template>

<ng-template #recordWith2ndDevice>
  <div
    class="alert alert-notice-header"
    [style.backgroundColor]="layoutService.gray50.backgroundColor"
    i18n="@@recordExerciseWithPhone"
  >Übung mit einem Smartphone aufzeichnen</div>
  <div
    class="text-center m-auto"
    style="width: 256px"
  >
    <a
      [href]="getQrCodeUrl()"
      target="_blank"
    >
      <qrcode
        [qrdata]="getQrCodeUrl()"
        [width]="256"
        [margin]="0"
      ></qrcode>
    </a>
  </div>
</ng-template>
<ng-template #recordAVideo>
  <div
    class="alert alert-notice-header"
    [style.backgroundColor]="layoutService.gray50.backgroundColor"
    i18n="@@recordExercise"
  >Übung aufzeichnen
  </div>


  <app-video-recording
    [is2ndDevice]="false"
    *ngIf="this.conversionStatus=='idle' && !isRecorded && !pack.secondDevice"
  ></app-video-recording>
  <ng-container *ngIf="this.conversionStatus!='idle'">

    <h3
      *ngIf="this.conversionProgress> 0"
      i18n="@@videoRecordConversion"
    >Video wird verarbeitet: {{this.conversionProgress | percent}}</h3>
    <h3
      *ngIf="this.conversionProgress == 0"
      i18n="@@videoRecordWaiting"
    >Video in Warteschlange</h3>

  </ng-container>
</ng-template>
<ng-template #showRecordedVideo>
  <div
    class="
    alert
    alert-notice-header"
    [style.backgroundColor]="layoutService.gray50.backgroundColor"
    i18n="@@recordedExercise"
  >Aufzeichnung der Übung
  </div>
  <div
    class="recordedvideo"
    style="display: none"
    [ngClass]="{'d-block': isRecorded, 'd-none': !isRecorded}"
    style="background: #000000"
  >
    <video
      #recording
      controls
      width="100%"
      controlsList="nodownload"
      disablePictureInPicture
      [src]="this.recordingSrc"
    ></video>
  </div>
  <hr>
  <button
    #deleteBtn
    class="btn btn-sm btn-sm btn-danger mb-2"
    (click)="openConfirmDeleteRecord()"
    [ngClass]="{'disabled': !participantVideo}"
    i18n="@@globalDelete"
  >Löschen
  </button>
</ng-template>

<div class="text-left">
  <app-document-tab-buttons
    [allparticipants]="allparticipants"
    [aRoute]="aRoute"
    [selectedEvent]="selectedeventobject"
  ></app-document-tab-buttons>

  <div class="row pl-4 pr-4 pb-4">
    <div class="col-12 col-lg-6">
      <div *ngIf="pack.secondDevice && !isRecorded">
        <ng-container *ngTemplateOutlet="recordWith2ndDevice"></ng-container>
      </div>

      <div *ngIf="!pack.secondDevice && !isRecorded">
        <ng-container *ngTemplateOutlet="recordAVideo"></ng-container>
      </div>

      <div *ngIf="(recorderState === 'inactive' || 'stopped') && isRecorded">
        <ng-container *ngTemplateOutlet="showRecordedVideo"></ng-container>
      </div>
    </div>
    <div
      class="col-12 col-lg-6"
      *ngIf="pack && pack.disableVideofeedback === 'normal'"
    >
      <div
        class="alert alert-notice-header"
        [style.backgroundColor]="layoutService.gray50.backgroundColor"
        i18n="@@inviteParticipantsForFeedback"
      >
        Teilnehmer zum Feedback einladen
      </div>
      <ul
        style="width:100%"
        role="menu"
      >
        <li
          role="menuitem"
          *ngFor="let participant of allparticipants"
          style="list-style-type: none"
        >
          <ng-container *ngIf="participantVideo; else disabledcheckbox">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="'participantcheckbox_'+participant.id"
              [checked]="participantVideo.metadata.feedbackpeers.includes(participant.id)"
              (change)="changeInvitationStatusForParticipant($event, participant.id)"
            >
            <label
              class="form-check-label"
              [for]="'participantcheckbox_'+participant.id"
            >{{participant.fullNameWithIndex}}</label>
          </ng-container>
          <ng-template #disabledcheckbox>
            <input
              type="checkbox"
              disabled
              class="mr-2"
            >
            <label
              class="form-check-label"
              [appLayout]="layoutService.textColor50"
              [for]="'participantcheckbox_'+participant.id"
            >{{participant.fullNameWithIndex}}</label>
          </ng-template>
          <table>
            <tr>
              <td valign="top"><i
                  class="ri-message-2-fill mr-2"
                  [appLayout]="layoutService.activeColor"
                  style="transform: scaleX(-1)"
                  [appLayoutColorFlip]="true"
                ></i></td>
              <td valign="top">
                <ng-template #nocomment><span
                    i18n="@@noFeedback"
                    [appLayout]="layoutService.textColor50"
                  >Kein Feedback</span>
                </ng-template>
                <ng-container *ngIf="ratingComment[participant.id]; else nocomment">
                  {{ratingComment[participant.id]}}
                </ng-container>
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
  </div>
</div>